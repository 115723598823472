/* always wrap long text */
body {
word-wrap: break-word;
}

/* force scrollbar to always show */
html {
    overflow-y: scroll;
}

.colorwhite, .colorwhite:hover {
    color: white;
}
.hover-underline:hover {
    text-decoration: underline;
}

.bg-main-1 {
    background-image: url("/assets/img/main-bg-1.jpg");
}

.bg-main-2 {
    background-image: url("/assets/img/main-bg-2.jpg");
}

/* region tippy themes */
.tippy-tooltip.white-theme {
    color: #777777;
    background-color: #ffffff;
    box-shadow: 0 4px 80px -8px rgba(0, 20, 60, 0.25);
    border-radius: 6px;
    padding: 30px;
}

/* arrow styling */
.tippy-tooltip.white-theme .tippy-roundarrow {
    fill: #ffffff;
}
.tippy-tooltip.white-theme .tippy-backdrop {
    background-color: #ffffff;
}
.tippy-popper[x-placement^=top] .tippy-tooltip.white-theme .tippy-roundarrow,
.tippy-popper[x-placement^=bottom] .tippy-tooltip.white-theme .tippy-roundarrow,
.tippy-popper[x-placement^=left] .tippy-tooltip.white-theme .tippy-roundarrow,
.tippy-popper[x-placement^=right] .tippy-tooltip.white-theme .tippy-roundarrow {
    transform: scale(2, 2.5);
}

/* endregion */

/* region shortcodes */

.cursor-pointer {
    cursor: pointer;
}

.block-fill-height-100 {
    display: table;
    width: 100%;
    height: 100vh;
}
/* endregion */
